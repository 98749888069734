<template>
  <modal
    name="connectorDetails"
    class="connection-modal"
    :scrollable="true"
    :height="'auto'"
    @closed="hideWelcomePage"
    :clickToClose="false"
  >
    <div class="">
      <div class="w-100">
        <div class="row" style="padding-top: 50px;">
          <div class="col p-0 welcome ml-4 mt-4 align-start">
            <img src="../../assets/group-21.png" alt class="welcome--image" />
          </div>

          <div class="col p-0 intro__content">
            <p class="Welcome-to-Data-Poem ProximaNovaRegular">
              {{ this.modalTitle.name }}
            </p>
            <w-tab
              :tabName="selectedTab"
              class="mb-3 d-flex"
              style="align-items: start;"
              :tabs="tabList"
              @activeTab="changeTab($event)"
            >
            </w-tab>
            <div
              class="Together-let-us-hel ProximaNovaRegular mt-0 "
              style="height: auto; width: 80% !important;"
              v-if="selectedTab === 'Create Connection'"
            >
              <div class="w-100 mb-5" style="background-color:#eff5ff">
                <w-white-dropdown
                  :options="allBrandDropdown"
                  :selectedOption="selectedBrand"
                  :labelText="'Brand*'"
                  class="w-100"
                  @input="selectBrand($event)"
                ></w-white-dropdown>
              </div>
              <b-form-input
                type="text"
                class="form-control input-control c-form-input m-0 mb-5 w-100"
                id="exampleInputEmail1"
                aria-describedby="text"
                placeholder="Product Name*"
                name="example-input-1"
                v-model="connectionName"
              ></b-form-input>
              <div class="w-100">
                  <div class="date-wraper d-flex w-100">
                    <div class="d-flex align-items-center w-100">
                      <w-datepicker
                        :placeHolder="'Select'"
                        :labelText="'Date'"
                        class=""
                        :selectedDate="date"
                        @getDate="selectedProduct($event)"
                      ></w-datepicker>
                    </div>
                  </div>
                </div>
              <div class="d-flex my-5 py-4">
                <w-button
                  class="cancel mr-4 px-5"
                  :buttonText="'Cancel'"
                  :isDisable="false"
                  :buttonLoader="'none'"
                  @buttonClicked="hideWelcomePage"
                >
                </w-button>

                <w-button
                  :buttonText="'Add Connection'"
                  :isDisable="buttonDisable"
                  :class="{ resetPasswordLink: buttonDisable }"
                  @buttonClicked="letsStart"
                  :buttonLoader="buttonLoaderAddConnection"
                >
                </w-button>
              </div>
            </div>
            <div
              class="Together-let-us-hel ProximaNovaRegular mt-0 "
              style="height: auto; width: 80% !important;"
              v-if="selectedTab === 'Send Access Request'"
            >
              <div class="w-100 mb-5" style="background-color:#eff5ff">
                <w-white-dropdown
                  :options="allBrandDropdown"
                  :selectedOption="selectedBrand"
                  :labelText="'Brand*'"
                  class="w-100"
                  @input="selectBrand($event)"
                ></w-white-dropdown>
              </div>
              <b-form-input
                type="text"
                class="form-control input-control c-form-input m-0 mb-5 w-100"
                id="exampleInputEmail1"
                aria-describedby="text"
                placeholder="Connection Name*"
                name="example-input-1"
                v-model="connectionName"
              ></b-form-input>
              <b-form-input
                type="email"
                class="form-control input-control c-form-input m-0 w-100"
                id="exampleInputEmail2"
                aria-describedby="emailHelp"
                placeholder="Enter Email*"
                name="example-input-1"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback" class="w-100"
                >Invalid Email.</b-form-invalid-feedback
              >
              <div class="d-flex my-5 py-4">
                <w-button
                  class="cancel mr-4 px-5"
                  :buttonText="'Cancel'"
                  :isDisable="false"
                  :buttonLoader="'none'"
                  @buttonClicked="hideWelcomePage"
                >
                </w-button>

                <w-button
                  :buttonText="'Send Access Request'"
                  :isDisable="sendButtonDisable"
                  :class="{ resetPasswordLink: sendButtonDisable }"
                  @buttonClicked="scheduleACall"
                  :buttonLoader="buttonLoaderAddConnection"
                >
                </w-button>
              </div>
            </div>
            <div
              class="Together-let-us-hel ProximaNovaRegular mt-0 "
              style="height: auto; width: 80% !important;"
              v-if="selectedTab === 'Talk to us'"
            >
              <div class="w-100 mb-5">
                <h3 class="ProximaNovaBold">Schedule a call with us</h3>
              </div>
              <div class="w-100 mb-5">
                <w-date-picker
                  v-model="selectedDate"
                  :placeholder="'Pick a time'"
                  format="DD:MM:YYYY  hh:mm A"
                  type="datetime"
                  :show-second="false"
                ></w-date-picker>
              </div>
              <div class="d-flex my-5 py-4">
                <w-button
                  class="cancel mr-4 px-5"
                  :buttonText="'Cancel'"
                  :isDisable="false"
                  :buttonLoader="'none'"
                  @buttonClicked="hideWelcomePage"
                >
                </w-button>

                <w-button
                  :buttonText="'Schedule Call'"
                  @buttonClicked="scheduleACall"
                  :buttonLoader="buttonLoaderAddConnection"
                >
                </w-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Button from "@/components/Profile/Button.vue";
import WhiteDropdown from "@/widgets/Dropdown.vue";
import Tab from "@/components/Solutions/Tab.vue";
import { validationMixin } from "vuelidate";
import { formMixin } from "@/mixins/form-mixin.js";
import { required, email } from "vuelidate/lib/validators";
import DatePickerMonth from "@/widgets/DatePicker.vue";
import DatePicker from "vue2-datepicker";
export default {
  name: "ConnectionModal",
  components: {
    "w-button": Button,
    "w-white-dropdown": WhiteDropdown,
    "w-tab": Tab,
    "w-date-picker": DatePicker,
    "w-datepicker": DatePickerMonth,
  },
  props: {},
  mixins: [validationMixin, formMixin],
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  data() {
    return {
      form: { email: "" },
      date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      selectedDate: "",
      tabList: [
        { category: "Create Connection" },
        // { category: "Send Access Request" },
        // { category: "Talk to us" },
      ],
      selectedTab: "Create Connection",
      modalTitle: "",
      buttonLoaderAddConnection: "normal",
      modalShow: false,
      connectionName: "",
      allBrandDropdown: [],
      selectedBrand: { text: "Select", id: "" },
      emailList: "",
    };
  },
  computed: {
    checkId() {
      if (this.modalTitle.connectionId === null) {
        return true;
      }
      return false;
    },
    buttonDisable() {
      if (
        this.connectionName !== "" &&
        this.selectedBrand.text !== "" &&
        this.selectedBrand.text !== "Select" &&
        this.buttonLoaderAddConnection === "normal" &&
        this.date !== ""
      ) {
        return false;
      }
      return true;
    },
    sendButtonDisable() {
      if (
        this.connectionName !== "" &&
        this.selectedBrand.text !== "" &&
        this.selectedBrand.text !== "Select" &&
        this.buttonLoaderAddConnection === "normal" &&
        !this.$v.form.email.$error
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    changeTab(tab) {
      if (this.checkId) {
        return;
      }
      this.selectedTab = tab;
    },
    selectedProduct(e){
      this.date = e
    },
    scheduleACall() {
      console.log("scheduleACall");
      this.hideWelcomePage();
    },
    selectBrand(e) {
      if (e === "Select") {
        if (e === this.selectedBrand) {
          return;
        }
        return;
      }
      this.selectedBrand = e;
    },
    open(title, list) {
      console.log(title)
      this.allBrandDropdown = [];
      this.modalTitle = {};
      this.connectionName = "";
      this.emailList = "";
      this.selectedBrand = { text: "Select", id: "" };
      this.allBrandDropdown = list;
      this.modalTitle = title;
      this.buttonLoaderAddConnection = "normal";
        this.selectedTab = "Create Connection";
      this.$modal.show("connectorDetails");
    },
    hideWelcomePage() {
      this.$modal.hide("connectorDetails");
    },
    letsStart() {
      var data = {
        name: this.connectionName,
        stack: 2,
        clientId: sessionStorage.getItem("clientId"),
        connectionId: this.modalTitle.connectionId,
        orgNumber: sessionStorage.getItem("subId"),
        brandId: this.selectedBrand.id,
        channelName: this.modalTitle.name,
      };
      this.buttonLoaderAddConnection = "loader";
      this.$emit("createConnection", data);
    },
  },
};
</script>
<style>
.connection-modal .vm--modal {
  width: 75% !important;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  margin: auto;
  display: block;
  top: 0 !important;
  left: auto !important;
}
</style>
<style lang="css" scoped>
span.start-from-fresh.mt-4 {
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
  cursor: pointer;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
#exampleInputEmail2:hover {
  color: #050505;
}
#exampleInputEmail1,
#exampleInputEmail2 {
  box-shadow: none;
  background-color: #eff5ff;
  border: 0px;
  font-weight: 500;
}
#exampleInputEmail2 {
  width: 378px;
  height: 60px;
  margin: 53px 1px 40px 30px;
  object-fit: contain;
  color: #9aafd4;
  font-family: ProximaNovaRegular;
  font-size: 16px;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 900px) {
  p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  p.Together-let-us-hel.ProximaNovaRegular.mt-0.w-75 {
    margin-left: 50px;
  }
  button.btn-effect-intro {
    margin-left: 50px;
  }
}
</style>
